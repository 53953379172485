import React, { CSSProperties, useMemo } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { TBlogPost } from '../../../../types/strapi/posts';
import { formatDateTextShort } from '../../../../utils';
import GridWrapper from '../../../grid-wrapper';
import { Picture } from '../../../Picture';
import Text from '../../../text';

import Arrow from './assets/arrow.inline.svg';

import './index.css';
import ItemContent from './ItemContent';
import Heading from '../../../heading';


const rootClassName = 'blog-articles';

const swiperSetting = { navigation: {
    prevEl: '.blog-articles__navigation-prev',
    nextEl: '.blog-articles__navigation-next'
} };


interface IProps {
    items: Array<TBlogPost>,
    carousel?: boolean,
    head?: boolean,
    main?: boolean,
    headText?: string,
    headBolder?: boolean,
}

const NavigationButton = () => {
    const cn = useClassnames();
    return (
        <div className={cn(`${rootClassName}__navigation`)}>
            <button className={cn(`${rootClassName}__navigation-prev ${rootClassName}__navigation-btn`)}>
                <Arrow />
            </button>
            <button className={cn(`${rootClassName}__navigation-next ${rootClassName}__navigation-btn`)}>
                <Arrow />
            </button>
        </div>
    );
};

const BlogArticles = ({ items, carousel = false, head = false, main = false, headText = 'Популярное', headBolder = false }: IProps) => {
    const cn = useClassnames();
    return (
        <GridWrapper className={cn(`${rootClassName}`, {
            [`${rootClassName}_carousel`]: carousel
        })}
        >
            {head && (
                <div className={`${rootClassName}__head`}>
                    {headBolder ? 
                    <Heading
                        level={3}
                        className={cn(`${rootClassName}__head-title`)}
                        dangerouslySetInnerHTML={{ __html: headText }}
                    />
                    :
                    <Text
                        size={2}
                        className={cn(`${rootClassName}__head-title`)}
                        dangerouslySetInnerHTML={{ __html: headText }}
                    />}
                    <NavigationButton />
                </div>
            )}
            {!carousel ? (
                items.map((post, index) => (
                    <div key={index} className={`${rootClassName}__item ${main && index === 0 ? `${rootClassName}__item_main` : ''}`}>
                        <ItemContent rootClassName={rootClassName} post={post} main={main && index === 0} />
                    </div>
                ))
            )
                : (
                    <Swiper className={`${rootClassName}__swiper`} slidesPerView="auto" centeredSlides={false} spaceBetween={20} modules={[Navigation]} {...swiperSetting}>
                        {items.map((post, index) => (
                            <SwiperSlide key={index} className={`${rootClassName}__item ${rootClassName}__slide`}>
                                <ItemContent rootClassName={rootClassName} post={post} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
        </GridWrapper>
    );
};

export default BlogArticles;
